import { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'

import type { Slider } from '~/types'

import { ArticleCardCarousel } from '../../nutrition/article-carousel'
import { ArticleRecommendedCarousel } from '../../nutrition/article-recommended'

interface EmblaCarouselProps {
  sliderData: Slider[]
}

export const EmblaCarouselTips = (props: EmblaCarouselProps) => {
  const { sliderData } = props
  const data = sliderData.concat(sliderData, sliderData)

  const randomSlideIndexOrder = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * sliderData.length)
    return randomIndex
  }, [sliderData])

  const [emblaRef, embla] = useEmblaCarousel(
    {
      loop: false,
      dragFree: false
    }
  )

  const rafId = useRef(0);

  const animate = useCallback(() => {
    if (!embla || !rafId.current) return;

    const engine = embla.internalEngine();
    engine.location.add(-1);
    engine.target.set(engine.location);
    if(engine.location.get() <= engine.scrollSnaps[7]){
      console.log('qweqe')
      engine.scrollLooper.loop(1)
      engine.translate.to(engine.location);
      rafId.current = requestAnimationFrame(reanimate);
    }else{                  
      engine.scrollLooper.loop(-1);
      engine.translate.to(engine.location);
      rafId.current = requestAnimationFrame(animate);
    }
  }, [embla]);

  const reanimate = useCallback(() => {
    if (!embla || !rafId.current) return;
    const engine = embla.internalEngine();
    engine.location.add(1);
    engine.target.set(engine.location);
    if(500 < engine.scrollSnaps[0] && engine.scrollSnaps[0] < 1000){
      if(engine.location.get() >= 160){
        engine.scrollLooper.loop(-1)
        engine.translate.to(engine.location);
        rafId.current = requestAnimationFrame(animate);
      }else{                  
        engine.scrollLooper.loop(1);
        engine.translate.to(engine.location);
        rafId.current = requestAnimationFrame(reanimate);
      }
    }else{
      if(engine.location.get() >= 30){
        engine.scrollLooper.loop(-1)
        engine.translate.to(engine.location);
        rafId.current = requestAnimationFrame(animate);
      }else{                  
        engine.scrollLooper.loop(1);
        engine.translate.to(engine.location);
        rafId.current = requestAnimationFrame(reanimate);
      }
    }
    
  }, [embla]);

  const startAutoScroll = useCallback(() => {
    rafId.current = requestAnimationFrame(animate);
  }, [animate]);

  const startAgain = useCallback(() => {
    rafId.current = requestAnimationFrame(reanimate);
  }, [reanimate]);

  const stopAutoScroll = useCallback(() => {
    rafId.current = cancelAnimationFrame(rafId.current) || 0;
  }, []);

  // const animate = useCallback(() => {
  //   if (!embla || !rafId.current) return;

  //   const engine = embla.internalEngine();
  //   engine.location.add(-0.5);
  //   engine.target.set(engine.location);
  //   engine.scrollLooper.loop(-1);
  //   if(engine.location.get() <= engine.scrollSnaps[engine.scrollSnaps.length - 1])
  //   {
  //     console.log('../////')
  //     engine.location.set(160)
  //     engine.translate.to(engine.location);
  //     rafId.current = requestAnimationFrame(animate);
  //   }else{
  //     engine.translate.to(engine.location);
  //     rafId.current = requestAnimationFrame(animate);
  //   }
    
  // }, [embla]);

  // const startAutoScroll = useCallback(() => {
  //   rafId.current = requestAnimationFrame(animate);
  // }, [animate]);

  // const stopAutoScroll = useCallback(() => {
  //   rafId.current = cancelAnimationFrame(rafId.current) || 0;
  // }, []);

  const mouseOver = useCallback(() => {
    stopAutoScroll()
  }, [embla]) 

  const mouseLeave = useCallback(() => {
    startAutoScroll()
  }, [embla]) 

  useEffect(() => {
    if (!embla) return;
    const engine = embla.internalEngine();
    if(engine.location.get() > 35 && engine.location.get() < 60){
      engine.location.set(45)
    }else{
      engine.location.set(160)
    }
    embla.on("pointerDown", stopAutoScroll);
    embla.on("settle", startAutoScroll);
    startAutoScroll()
    return () => stopAutoScroll();
  }, [embla, startAutoScroll, stopAutoScroll, startAgain]);


  useEffect (() => {
    const {innerWidth, innerHeight} = window;
    window.onscroll = function (){
      if(innerWidth < 400){
        console.log('?', document.documentElement.scrollTop)
        if(document.documentElement.scrollTop <= 700 || document.documentElement.scrollTop >= 1300){
          stopAutoScroll()
        }
        if(document.documentElement.scrollTop <= 3600 || document.documentElement.scrollTop >= 4200){
          stopAutoScroll()
        }
      }else{
        // console.log('????', )
        if(document.documentElement.scrollTop <= 2800 || document.documentElement.scrollTop >= 3600){
          stopAutoScroll()
        }
        if(document.documentElement.scrollTop <= 500 || document.documentElement.scrollTop >= 1300){
          stopAutoScroll()
        }
      }
      
    }
  })


  return (
    <section className="h-full max-w-full">
      <div className="embla relative" ref={emblaRef} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
        <div className="grid grid-flow-col">
          {data.map(slide => {
            return <ArticleRecommendedCarousel key={slide.id} data={slide} />
          })}
        </div>
      </div>
    </section>
  )
}
